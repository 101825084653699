import { CDN_IMAGE_URL } from "./constant";

export const TOKEN_LIST = {
  Base: [
    {
      id: 8453,
      address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
      symbol: "ETH",
      name: "Ether",
      decimals: 18,
      logoURI: "https://icons.llamao.fi/icons/chains/rsz_ethereum.jpg",
    },
    {
      id: 8453,
      address: "0x4ed4E862860beD51a9570b96d89aF5E1B0Efefed",
      symbol: "DEGEN",
      name: "Degen",
      decimals: 18,
      logoURI: "https://basescan.org/token/images/degentips_32.png",
    },
    {
      id: 8453,
      address: "0x34C990Ee5aA627E9304234cc59b0734163eAc06b",
      symbol: "BRUNETTE",
      name: "Brunette",
      decimals: 18,
      logoURI: `${CDN_IMAGE_URL}/web-assets/brunette_token.jpg`,
    },
    {
      id: 8453,
      address: "0xAfb89a09D82FBDE58f18Ac6437B3fC81724e4dF6",
      symbol: "DOG",
      name: "The Doge NFT",
      decimals: 18,
      logoURI: "https://basescan.org/token/images/dogetokenbase_32.png",
    },
    {
      id: 8453,
      address: "0x5B5dee44552546ECEA05EDeA01DCD7Be7aa6144A",
      symbol: "TN100x",
      name: "The Next 100x Memecoin on Base",
      decimals: 18,
      logoURI: "https://icons.llamao.fi/icons/chains/rsz_base.jpg",
    },
    {
      id: 8453,
      address: "0xE3086852A4B125803C815a158249ae468A3254Ca",
      symbol: "$mfer",
      name: "mfercoin",
      decimals: 18,
      logoURI: "https://basescan.org/token/images/mfercoinbase_32.png",
    },
  ],
  Ham: [
    {
      id: 5112,
      address: "0x0000000000000000000000000000000000000000",
      symbol: "ETH",
      name: "Ether",
      decimals: 18,
      logoURI: "https://icons.llamao.fi/icons/chains/rsz_ethereum.jpg",
    },
  ],
  Og: [
    {
      id: 16600,
      address: "0xeE597D163C3Fef7B594cC11746BC4099F4323Fd3",
      symbol: "AOGI",
      name: "AOGI",
      decimals: 18,
      logoURI: "https://icons.llamao.fi/icons/chains/rsz_ethereum.jpg",
    },
  ],
  "Arbitrum One": [
    {
      id: 42161,
      address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
      symbol: "USDT",
      name: "Tether USD",
      decimals: 18,
      logoURI: "https://arbiscan.io/token/images/tether_32.png",
    },
    {
      id: 42161,
      address: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
      symbol: "USDC",
      name: "USD Coin",
      decimals: 18,
      logoURI: "https://arbiscan.io/token/images/centre-usdc_28.png",
    },
  ],
  Polygon: [
    {
      id: 137,
      address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
      symbol: "POL",
      name: "Polygon Token",
      decimals: 18,
      logoURI: "https://polygonscan.com/token/images/polygonmatic_new_32.png",
    },
  ],
  Morph: [
    {
      id: 2818,
      address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
      symbol: "ETH",
      name: "Ether",
      decimals: 18,
      logoURI: "https://icons.llamao.fi/icons/chains/rsz_ethereum.jpg",
    },
  ],
  "Camp Network Testnet V2": [
    {
      id: 325000,
      address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
      symbol: "ETH",
      name: "Ether",
      decimals: 18,
      logoURI: "https://icons.llamao.fi/icons/chains/rsz_ethereum.jpg",
    },
  ],
  "Story Aeneid Testnet": [
    {
      id: 1315,
      address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
      symbol: "IP",
      name: "IP",
      decimals: 18,
      logoURI: "https://icons.llamao.fi/icons/chains/rsz_ethereum.jpg",
    },
  ],
  Story: [
    {
      id: 1514,
      address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
      symbol: "IP",
      name: "IP",
      decimals: 18,
      logoURI: "https://icons.llamao.fi/icons/chains/rsz_ethereum.jpg",
    },
    {
      id: 1514,
      address: "0x674843C06FF83502ddb4D37c2E09C01cdA38cbc8",
      symbol: "USDT",
      name: "Tether USD",
      decimals: 18,
      logoURI: "https://arbiscan.io/token/images/tether_32.png",
    },
    {
      id: 1514,
      address: "0xF1815bd50389c46847f0Bda824eC8da914045D14",
      symbol: "USDC",
      name: "USD Coin",
      decimals: 18,
      logoURI: "https://arbiscan.io/token/images/centre-usdc_28.png",
    },
  ],
  Ethereum: [
    {
      id: 1,
      address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
      symbol: "ETH",
      name: "Ether",
      decimals: 18,
      logoURI: "https://icons.llamao.fi/icons/chains/rsz_ethereum.jpg",
    },
  ],
  "OP Mainnet": [
    {
      id: 10,
      address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
      symbol: "ETH",
      name: "Ether",
      decimals: 18,
      logoURI: "https://icons.llamao.fi/icons/chains/rsz_ethereum.jpg",
    },
  ],
  Zora: [
    {
      id: 7777777,
      address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
      symbol: "ETH",
      name: "Ether",
      decimals: 18,
      logoURI: "https://icons.llamao.fi/icons/chains/rsz_ethereum.jpg",
    },
  ],
};
